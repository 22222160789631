import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import LoginNavbar from "./LoginNavbar";
import Footer from "components/framework/footer/Footer";

type Props = {
  children: React.ReactNode;
};

export default function LoginLayout(props: Props) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/callback")
      localStorage.setItem('location', location.pathname)
  }, [location])
  
  return (
    <div className="main-content">
      <LoginNavbar />
      {props.children}
      <Footer />
    </div>
  );
}
