import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import React, { useContext, ReactNode, useCallback, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";
import { useProfileSwitcher } from "services/customHooks/useProfileSwitcher";

type Props = {
  children: ReactNode;
  path?: string | string[];
  exact?: boolean;
  userPermissions?: PermissionType[];
};

const PrivateRoute = (props: Props) => {
  const { appContext } = useContext(AppContext);
  var isAuthorized = true;

  const location = useLocation();
  const cid = new URLSearchParams(location.search).get("cid");

  const {navigate: switchProfile} = useProfileSwitcher();
  
  useEffect(()=> {
    if (cid){
      switchProfile(`${props.path}`,cid);
    }
  },[cid, switchProfile, props.path]);

  if (!appContext.selectedClient) {
    isAuthorized = false;
  }

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={({ location }) =>
        appContext.localStorageInfo.authenticationInfo.authenticated ? (
          isAuthorized ? (
            <>{props.children}</>
          ) : (
            <Redirect to={{ pathname: "/unauthorized", state: { from: location } }} />
          )
        ) : (
          //<></>
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () =>  <LoginLayout>
  <LoginHeader title="" />
  <LoadingIndicator className="center" white />
</LoginLayout>,
});