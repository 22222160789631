import classNames from "classnames";
// import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ReactTable from "components/framework/table/ReactTable";
import _ from 'lodash';
import Papa from "papaparse";
import React, { FormEvent, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { QueryType } from "services/apis/types/toll-free-numbers/RequestTollFreeQuery";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import { StatusType, TollFreeQueryDto } from "services/apis/types/toll-free-numbers/TollFreeQueryDto";
import { TollFreeTextQueryDto } from "services/apis/types/toll-free-numbers/TollFreeTextQueryDto";
import { AppContext } from "services/appContext/AppContext";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { CNAMReports } from "services/Reports/CNAMReports";
import { FraudScoreReports } from "services/Reports/FraudScoreReports";
import { handleError } from "services/util/ApiUtil";
import ExploreSearch from "./ExploreSearch";
import TollFreeQueryReportModal from "./TollFreeQueryReportModal";
import TollFreeQueryReserveModal from "./TollFreeQueryReserveModal";
import TollFreeQueryUpsertModal from "./TollFreeQueryUpsertModal";
import { TollFreeHeaders } from "./types/TollFreeHeaders";

export default function TollFreeQueryCreate() {
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [errorMessageId, setErrorMessageId] = useState("");
    const [focusedSearchInput, setFocusedSearchInput] = useState(false);
    const [tollFreeQueryResult, setTollFreeQueryResult] = useState<TollFreeQueryDto>();
    const [tollFreeQueryRoutingResult, setTollFreeQueryRoutingResult] = useState<TollFreeQueryDto>();
    const [tollFreeQueryTextStatusResult, setTollFreeQueryTextStatusResult] = useState<TollFreeTextQueryDto>();
    const [tollFreeNotInDatabase, setTollFreeNotInDatabase] = useState<string[]>([]);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [, setShowLoadingIndicatorRouting] = useState(false);
    const [, setShowLoadingIndicatorTextStatus] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const { appContext } = useContext(AppContext);
    const [showReserveModal, setShowReserveModal] = useState(false);
    const [, setTollFreeHeaders] = useState<TollFreeHeaders>();
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedTollFreeNumbers, setSelectedTollFreeNumbers] = useState<Array<string>>([]);
    const [showReports, setShowReports] = useState(false);
    const [tollFreeFraud, setTollFreeFraud] = useState<Array<FraudScoreReports>>();
    const [tollFreeCnam, setTollFreeCnam] = useState<Array<CNAMReports>>();
    const [queryResult, setQueryResult] = useState<Array<any>>([]);
    const [selectedReportType, setSelectedReportType] = useState<Array<any>>([]);
    const [cnamCharge, setCnamCharge] = useState<any>();
    const [fraudCharge, setFraudCharge] = useState<any>();
    const defaultColumns = useMemo(() => getColumns(intl), [intl]);
    const [defaultTableData] = useState<Array<any>>([]);
    const [showDefault, setShowDefault] = useState<boolean>(false);


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const inputValue = searchInputValue.trim();
        const errorMessageIdResult = validateSearchValue(inputValue);
        setErrorMessageId(errorMessageIdResult);
        setTollFreeNotInDatabase([]);

        if (!errorMessageIdResult) {
            let newNumberList: any = searchInputValue.trim();
            const startingPositionIsOne = newNumberList.startsWith("1");
            if (startingPositionIsOne === true) {
                newNumberList = newNumberList.substring(1);
            }
            let resultOfTenDigitsArray: any[] = [];
            let resultantNumberList = newNumberList.replaceAll('\t', '$').replaceAll('\n', '$').replaceAll('\r', '$').split('$');
            // newNumberList.replace(/\-/g, "").replace(/\,/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\{/g, "").replace(/\}/g, "").replace(/\ /g, "").replace(/[^\w ]/g, '').match(/.{0,10}/g);
            resultOfTenDigitsArray = newNumberList.replace(/-/g, "").replace(/,/g, "").replace(/()/g, "").replace(/{}/g, "").replace(/ /g, "").replace(/[^\w ]/g, '').match(/.{0,10}/g);
            resultantNumberList = resultOfTenDigitsArray;
            let resultOfCommaRemovalNumberList = resultantNumberList.filter(e => e !== ',');
            let resultOfEmptyRemovalNumberList = resultOfCommaRemovalNumberList.filter(e => e);
            let uniqueResultantNumberList = resultOfEmptyRemovalNumberList.filter((uniqueNumber, index) => {
                return (
                    uniqueNumber && resultOfEmptyRemovalNumberList.indexOf(uniqueNumber) === index
                );
            });
            if (uniqueResultantNumberList.length > 0) {
                setSelectedTollFreeNumbers(uniqueResultantNumberList);
                setShowReports(true);
            }
        }
    };
    const reportTypeSelection = (selectedReports: any, tollFreeList: any) => {
        setSelectedReportType(selectedReports)
        setShowLoadingIndicator(true);
        TollFreeNumbersApi.queryTollFrees({ numbers: tollFreeList, queryType: QueryType.Status })
            .then((result) => {
                if (result) {
                    setTollFreeQueryResult(result);
                    setShowDefault(true);
                }
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
        const tollFreeNumberRequest = {
            tollFreeNumbers: tollFreeList
        };
        selectedReports && selectedReports.forEach((reportOption: any) => {
            if (reportOption.key === 'CNAM' && reportOption.status === true) {
                TollFreeNumbersApi.getCNAMTollFreeNumber(tollFreeNumberRequest)
                    .then((result) => {
                        if (isMounted.current) {
                            setTollFreeCnam(result.tollFreeCnams)
                            setShowDefault(true);
                            console.log('CnamResult', result)
                        }
                    })
                    .catch((error) => {
                        handleError(error);
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setShowLoadingIndicator(false);
                        }
                    });
            }
            if (reportOption.key === 'Fraud Score' && reportOption.status === true) {
                TollFreeNumbersApi.getFraudQueryTollFreeNumber(tollFreeNumberRequest)
                    .then((result) => {
                        if (isMounted.current) {
                            setTollFreeFraud(result.tollFreeFrauds);
                            setShowDefault(true);
                            console.log('Fraudquery', result)
                        }
                    })
                    .catch((error) => {
                        handleError(error);
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setShowLoadingIndicator(false);
                        }
                    });
            }
        })
    }

    const reportFees = (cnamFees: any, fraudFees: any, reportType: any) => {
        if (!isNaN(cnamFees) && cnamFees > 0 && reportType.some(x => x.key === "CNAM" && x.status === true)) {
            setCnamCharge(cnamFees);
        }
        if (!isNaN(fraudFees) && fraudFees > 0 && reportType.some(x => x.key === "Fraud Score" && x.status === true)) {
            setFraudCharge(fraudFees);
        }
    }
    useEffect(() => {
        if ((tollFreeFraud && tollFreeFraud.length > 0) || (tollFreeCnam && tollFreeCnam.length > 0)) {
            if (tollFreeFraud && tollFreeFraud.length > 0) {
                tollFreeFraud.forEach((x) => {
                    x['Number'] = x['number'];
                })
            }
            if (tollFreeCnam && tollFreeCnam.length > 0) {
                tollFreeCnam.forEach((x) => {
                    x['Number'] = x['number'];
                })
            }
            const mergedValues = _.values(_.merge(_.keyBy(tollFreeFraud, 'number'), _.keyBy(tollFreeCnam, 'number')))
            setQueryResult(mergedValues);
        }
    }, [tollFreeCnam, tollFreeFraud])

    useEffect(() => {
        const resultantNumber: any[] = [];
        if (tollFreeQueryResult && tollFreeQueryResult.reportFile) {
            console.log('dataparser', tollFreeQueryResult);
            let decodeTollFreeStatusQueryResult = Buffer.from((tollFreeQueryResult) ? tollFreeQueryResult.reportFile : '', "base64").toString();
            Papa.parse(decodeTollFreeStatusQueryResult, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    console.log("Finished:", results.data);
                    if (results.data) {
                        results.data.forEach((x) => {
                            for (const [key, value] of Object.entries(x)) {
                                if (key === 'In Database') {
                                    if (value === 'Yes') {
                                        resultantNumber.push(x.Number);
                                        // inDatabase = true;
                                    } else {
                                        const newtollFreeNotInDatabase: string[] = [...tollFreeNotInDatabase]
                                        newtollFreeNotInDatabase.push(x.Number);
                                        setTollFreeNotInDatabase(newtollFreeNotInDatabase)
                                    }
                                }
                            }
                        })
                    }
                }
            });
            if (resultantNumber.length > 0) {
                setShowLoadingIndicatorRouting(true);
                TollFreeNumbersApi.queryTollFrees({ numbers: resultantNumber, queryType: QueryType.Routing })
                    .then((result) => {
                        console.log('routing', result);
                        setTollFreeQueryRoutingResult(result);
                        setShowDefault(true);
                    })
                    .catch((error) => {
                        if (error && error.message && !error.message.includes("Following"))
                            handleError(error);
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setShowLoadingIndicatorRouting(false);
                        }
                    });
                setShowLoadingIndicatorTextStatus(true);
                TollFreeNumbersApi.queryTollFreeTexts({ tollFreeNumbers: resultantNumber })
                    .then((result) => {
                        console.log('text', result);
                        setTollFreeQueryTextStatusResult(result);
                        setShowDefault(true);
                    })
                    .catch((error) => {
                        if (error && error.message && !error.message.includes("Following"))
                            handleError(error);
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setShowLoadingIndicatorTextStatus(false);
                        }
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tollFreeQueryResult, isMounted]);

    const handleSearchChange = (searchValue: string) => {
        setErrorMessageId("");
        setSearchInputValue(searchValue);
    };

    const handleCreateDropdownItemButtonClickForReserve = (tollFreeHeaders: TollFreeHeaders) => {
        setShowReserveModal(true);
        setShowDropdown(false);
        setTollFreeHeaders(tollFreeHeaders);
    };

    const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

    useEffect(() => {
        setTollFreeQueryResult(undefined);
        setTollFreeQueryRoutingResult(undefined);
        setTollFreeQueryTextStatusResult(undefined);
    }, [appContext.selectedClient]);

    const handleChangeSearchNumber = (searchNumbers: TollFreeNumbersDto) => {
        const newSelectedTollFreeNumbers = [...selectedTollFreeNumbers]
        if (!searchNumbers.modifiedStatus) {
            setSelectedTollFreeNumbers(newSelectedTollFreeNumbers.filter(x => x !== searchNumbers.Number));
            return;
        }
        if (!newSelectedTollFreeNumbers.find(x => x === searchNumbers.Number)) {
            setSelectedTollFreeNumbers([...newSelectedTollFreeNumbers, searchNumbers.Number]);
        }
    }

    const handleSelectAll = (searchNumbers: TollFreeNumbersDto[]) => {
        const newSelectedTollFreeNumbers = [...selectedTollFreeNumbers]
        searchNumbers.forEach(x => {
            const index = newSelectedTollFreeNumbers.indexOf(x.Number);
            if (!x.modifiedStatus) {
                if (index > -1) { // only splice array when item is found
                    newSelectedTollFreeNumbers.splice(index, 1); // 2nd parameter means remove one item only
                }
                return
            }
            if (index < 0) {
                newSelectedTollFreeNumbers.push(x.Number);
            }
        })
        setSelectedTollFreeNumbers(newSelectedTollFreeNumbers);
    }

    return (
        <>
            <Container fluid className="mb-5 mt-5">
                <Row>
                    <Col className="p-0">
                        <h1 className="display-3 atl-toll-free-explorer-bg">
                            <FormattedMessage id="tollfree.explore.title" />
                        </h1>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col xs={8} className="mb-1 p-0">
                        <Form onSubmit={handleSubmit}>
                            <FormGroup
                                className={classNames("input-group-merge pc-search-container mb-0", {
                                    focused: focusedSearchInput
                                })}>
                                <InputGroup className="input-group-merge">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        className="pc-search"
                                        placeholder={intl.formatMessage({ id: "tollfreeork.search.placeholder" })}
                                        value={searchInputValue}
                                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                                        onFocus={() => setFocusedSearchInput(true)}
                                        onBlur={() => setFocusedSearchInput(false)}
                                    />
                                </InputGroup>
                                <Button color="warning" type="submit" className="pc-btn ml-4">
                                    {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                    <FormattedMessage id="tollfree.search.button" />
                                </Button>
                            </FormGroup>
                            {errorMessageId && (
                                <div className="text-red text-sm mt-1">
                                    <FormattedMessage id={errorMessageId} />
                                </div>
                            )}
                        </Form>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end mb-1 p-0">
                        <div className="pc-create-dropdown p-0">
                            <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                                <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                                    <Button
                                        className="pc-btn ml-4"
                                        color="warning"
                                        type="button"
                                    >
                                        <i className="fa fa-plus-square mr-2" />
                                        <FormattedMessage id="tollfree.button.create.title" />
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu positionFixed={true} className="dropdown-menu-md dropdown-menu-white bg-dark pc-no-shadow text-white">
                                    <Row className="shortcuts px-4">
                                        <Col
                                            className="shortcut-item cursor-pointer mr-3"
                                            onClick={() =>
                                                handleCreateDropdownItemButtonClickForReserve(TollFreeHeaders.Reserve)
                                            }
                                            xs="4">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-pc-orange">
                                                <i className="fa fa-check-double" />
                                            </span>
                                            <small>
                                                <FormattedMessage id="tollfree.table.actions.bulkQuery" />
                                            </small>
                                        </Col>
                                    </Row>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                {showSettingsModal && (
                    <TollFreeQueryUpsertModal closeModal={() => setShowSettingsModal(false)} />
                )}
                {showCreateModal && (
                    <TollFreeQueryReserveModal
                        closeModal={() => setShowCreateModal(false)}
                    />
                )}
            </Container>
            {
                // (showLoadingIndicator || showLoadingIndicatorRouting || showLoadingIndicatorTextStatus) ? (
                //     <>
                //         <LoadingIndicator white />
                //     </>
                // ) : 
                ((
                    (tollFreeQueryResult?.statusType === StatusType.Complete ? (
                        ((tollFreeQueryResult?.reportFile) || tollFreeQueryRoutingResult || tollFreeQueryTextStatusResult) &&
                        <Container fluid className="mb-5">
                            <ExploreSearch tollFreeNumberQuery={tollFreeQueryResult} tollFreeQueryRouting={tollFreeQueryRoutingResult} tollFreeTextStatus={tollFreeQueryTextStatusResult}
                                tollFreeNotInDatabase={tollFreeNotInDatabase}
                                selectedTollFreeNumbers={selectedTollFreeNumbers}
                                handleChangeSearchNumber={handleChangeSearchNumber}
                                handleSelectAll={handleSelectAll}
                                selectedReportType={selectedReportType}
                                queryResult={queryResult}
                                cnamCharge={cnamCharge}
                                fraudCharge={fraudCharge}
                            />
                        </Container>)
                        : ((tollFreeQueryResult?.statusType === StatusType.Pending) &&
                            <Row
                                className="align-items-center justify-content-center">
                                <Col xs="10">
                                    <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
                                        <span className="alert-icon"><i className="fa fa-exclamation-triangle fa-2x"></i></span>
                                        <span className="alert-text"><FormattedMessage id="tollFree.bulkQuery.status.pending" /></span>
                                    </div>
                                </Col>
                            </Row>))
                ))
            }
            {(!showDefault) && (
                <Container fluid className="mb-5">
                    <ReactTable
                        showViewSettings
                        hideSearch
                        title={intl.formatMessage({ id: "explore.subtable.title" })}
                        data={defaultTableData}
                        autoResetPage={false}
                        // handleSelectAll={handleSelectAll}
                        // areSelectedAll={areSelectedAll}
                        showDownload
                        columns={defaultColumns}
                    />
                </Container>
            )}
            {showReserveModal && (
                <TollFreeQueryReserveModal
                    closeModal={() => setShowReserveModal(false)}
                />
            )}
            {showReports && (
                <TollFreeQueryReportModal selectedTollFreeNumbers={selectedTollFreeNumbers} closeModal={() => setShowReports(false)} reportTypeSelection={reportTypeSelection} reportFees={reportFees} />
            )}
        </>
    );
}

const validateSearchValue = (searchInputValue: string): string => {
    if (!searchInputValue) {
        return "tollfreeork.search.emptySearch";
    }
    let newNumberList: any = searchInputValue.trim();
    const startingPositionIsOne = newNumberList.startsWith("1");
    if (startingPositionIsOne === true) {
        newNumberList = newNumberList.substring(1);
    }
    let resultOfTenDigitsArray: any[] = [];
    let resultantNumberList = newNumberList.replaceAll('\t', '$').replaceAll('\n', '$').replaceAll('\r', '$').split('$');
    resultOfTenDigitsArray = newNumberList.replace(/-/g, "").replace(/,/g, "").replace(/()/g, "").replace(/{}/g, "").replace(/ /g, "").replace(/[^\w ]/g, '').match(/.{0,10}/g);
    resultantNumberList = resultOfTenDigitsArray;

    let resultOfCommaRemovalNumberList = resultantNumberList.filter(e => e !== ',');
    let resultOfEmptyRemovalNumberList = resultOfCommaRemovalNumberList.filter(e => e);
    let uniqueResultantNumberList = resultOfEmptyRemovalNumberList.filter((uniqueNumber, index) => {
        return (
            uniqueNumber && resultOfEmptyRemovalNumberList.indexOf(uniqueNumber) === index
        );
    });

    if (!uniqueResultantNumberList || uniqueResultantNumberList.length === 0) {
        return "tollfreeork.search.invalidLength"
    }

    return "";
};

const getColumns = (intl: IntlShape) => [
    {
        Header: intl.formatMessage({ id: "explore.table.number" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.status" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.setupdate" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.resporgId" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.companyName" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.contactnumber" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.myNumber" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.template" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.network" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.carrier" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.cprNotes" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.effectiveDate" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.textStatus" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "tollfree.reportTable.cnam" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "tollfree.reportTable.score" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "explore.table.propensity" }),
        className: "text-break",
    },
    {
        Header: intl.formatMessage({ id: "tollfree.table.actions" }),
        className: "text-break",
    }
];
